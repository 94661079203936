<template>
	<div style="display: flex;flex-direction: column;align-items: center;justify-content: center;	" class="font-famliy">
		<div class="zxyj-content-box" >
			<div class="banner-content">
				<div class="banner">
						<img style="height: 400px;" src="@/assets/images/banner/project.png" alt="加载失败" />
				</div>
			</div>
		</div>
		<div class="project" style="margin-bottom: 100px;">
			<div class="xzyj-content" style="display: flex;flex-direction: column;">
				<div style="display: flex;align-items: center;margin-top: 30px;margin-left: 15px;">
					<el-breadcrumb separator="/">
					 <el-breadcrumb-item :to="{ path: '/' }"><span>首页</span></el-breadcrumb-item>
					 <el-breadcrumb-item ><span>{{route.meta.title}}</span></el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div style="display: flex;flex-direction: row;justify-content:flex-start;margin-left: 10px;">
					<div style="display: flex;margin-top: 30px;">
				  <el-date-picker  
                        class="dateCalz"
				      v-model="value1"
				      type="daterange"
				      range-separator="-"
					  start-placeholder="开始时间"
					  end-placeholder="结束时间"
					  @change="changeDate"
					 value-format="yyyy-MM-dd"
				      >
					  
				    </el-date-picker>
					</div>
					<div style="display: flex;margin-top: 30px;">
						<el-cascader 
						  
							:props="props"
						     class="selectCalz"
							 @change="locationsChange"
							 :placeholder="placdata"
						
							 v-model="locationsData"
							 clearable
						></el-cascader>
					
					  </div>
					
				</div>
			<!-- 项目案例 -->
			<div >
				<div class="xzyj-content position-relative">
					<div class="xst-project">
						<div class="xst-project-content">
							<div class="xst-project-list" style="flex-wrap: wrap;">
								<a class="xst-project-item xst-left-margin" v-for="(item, i) in cases" :key="i"  v-on:mouseenter="showDialog(i,item)" v-on:mouseleave="hideDialog(i,item)" :href="['/caseDetail/index.html?id='+item.id+'&type=case']" onclick="window.open(this.href);return false">
									<img class="xst-project-img" :src="item.image" alt="加载失败" />
									<div class="xst-project-title-back" v-if="!ishow || i !=current"></div>
									<div class="xst-project-title" v-if="!ishow || i !=current">{{ item.title }}</div>
									<!--弹出层-->
									<div style="display: flex; flex-direction: column;" class="handleDialog" v-if="ishow && i==current">
										<div class="content">
											<div class="content-title font-overflow-line">{{ item.title }}</div>
											<div class="content-sub-title font-overflow-lines">{{ item.subTitle }}</div>
										</div>
										<a class="content-button" :href="['/caseDetail/index.html?id='+item.id+'&type=case']" onclick="window.open(this.href);return false">查看详情</a>
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="page" style="text-align: center;margin-top: 60px;">
					<el-pagination background layout="prev, pager, next" :page-size="params.pageSize" :total="total" @current-change="
							(val) => {
								changePage(val);
							}
						">
					</el-pagination>
				</div>
			</div>
		</div>
		<!-- <div style="height: 100px"></div> -->
	</div>

</template>

<script>
	import http from "@/common/utils/Http";
	export default {
		metaInfo: {
			title: "智能爬架工程案例-鑫昇腾",
			meta:[
				{name:"keywords", content: "爬架施工案例,智能爬架施工方案,爬架分包"},
				{name:"description", content: "江苏鑫昇腾科技发展股份有限公司专注于建筑爬架的设计、制造和专业承包;提供爬架租赁、销售、运营、安全监管、技术服务等一站式解决方案。"}
			]
		},
		data() {
			return {
				value:'',
				areaOptons:[],
				cases: [],
				 value1: '',
				total: 0,
				route:this.$route,
				params: {
					pageNum: 1,
					pageSize: 12
				},
				ishow: false,
				current: 0, //当前操作按钮
				 locationsData:[],
				  placdata:"省/市",
				props: {
				  lazy: true,
				  async lazyLoad(node, resolve) {
				    const { level } = node;
				    if (level == 0) {
				      let param={
				        pregionCode:"000000"
				      }
				
				      http.api.get("/site/system/region/getProvinceList", {
				      	param
				      }).then(res=>{
						
				      const nodes= res.data.rows.map((item,index) => {
									console.log(index)
				          return {
				            value: item.regionCode,
				            label: item.regionName,
				            leaf: false
				          };
				        });
						console.log("nodes:",nodes)
				         resolve(nodes);
				      });//获取省接口
				
				     
				
				    } else if (level == 1) {
				     console.log("node1",node)
				     let param1={
				       pRegionCode:node.data.value
				     };
					 console.log("node:",param1)
				     var nodes =null;
				       http.api.get("/site/system/region/getProvinceList", 
				       	{
							params:param1
						}
				       ).then(res=>{
				   console.log("res:",res)
				      const nodes= res.data.rows.map((item,index) => {
								console.log(index);
				          return {
				            value: item.regionCode,
				            label: item.regionName,
				            leaf: true
				          };
				        });
						
				         resolve(nodes);
				      });//获取市接口
				
				      nodes.unshift({
				        value: 0,
				        label: "全部",
				        leaf: true
				      });
				
				    } 
				  }
				}
			};
		},
		activated() {
			
			this.getCases();
		},
		methods: {
			locationsChange() {
			
			    	      this.params.province=this.locationsData[0];
						   this.params.city=this.locationsData[1];
						   this.getCases();
			    	    },
			changeDate(){
				this.params.start=this.value1[0];
				 this.params.end=this.value1[1];
				this.getCases();
			
			},
			getCases() {
				let params = this.params;
				params.header='1';
				http.api.get("/site/case/list", {
					params
				}).then((res) => {
					console.log(res.data.rows)
					this.cases = this.changeCase(res.data.rows);
					
					this.total = res.data.total
				});
			},
			changePage(val) {
				this.params.pageNum = val
				this.getCases()
			},
			// 转换案例
			changeCase(arr) {
				let newArr = [];
				arr.forEach((row) => {
					let sub = {};
					sub.image = row.caseUrl;
					sub.id = row.siteCaseId;
					sub.title = row.caseTitle;
					sub.subTitle = row.caseSubtitle;
					newArr.push(sub);
				});
				return newArr;
			},
			// 打开详情页
			detail(id) {
				this.$router.push({
					path: "news_Detail",
					query: {
						type: "case",
						id: id
					},
				});
			},
			showDialog(index, item) {

				console.log(index, item)
				this.ishow = true;
				this.current = index;
			},
			//隐藏蒙层
			hideDialog(index, item) {
				console.log(index, item)
				this.ishow = false;
				this.current = null;
			},
		},
	};
</script>

<style lang="scss" scoped>
	.font-famliy  {
		div,a,span {
			font-family: MicrosoftYaHei;
		}
	}
	.banner-content {	margin-top: 80px;	position: relative;	height: 400px;width: 100%;}
	.banner {	height: 100%;	width: 100% !important;	overflow: hidden;	text-align: center;	margin: 0 auto;	padding: 0;	z-index: 1;}
	// 产品介绍开始-------------------------------
	.xst-product {padding-top: 30px;}
	.xst-product-ctrl {display: flex; height: 130px; width: 100%;background-color: #FFFFFF;}
	.xst-ctrl {border-radius: 2px 2px 0px 0px;position: relative; line-height: 30px; color:#122A88;display: flex;height: 130px;width: 240px;font-size: 14px;justify-content: center;align-items: center;flex-direction: column;border-right: solid 0.5px #eee;border-left: solid 0.5px #eee;cursor: pointer;}
	.xst-ctrl img {width: 56px; height: 56px;}
	.xst-ctrl-selected {background-color: #122A88; color: #fff;}
	.xst-ctrl-bottom{position: absolute;margin:auto;left: 0;right: 0;bottom: -18px;width: 16px;}
	.xst-ctrl-bottom img{width: 16px; height: 9px;}
	
	.xst-product-content{display: flex;align-items: center; flex-direction: column;justify-content: center;height: 346px;width: 100%;}
	.xst-product-bakimg1{background-image: url(../../assets/images/home/homepage-img-远程系统.png)}
	.xst-product-bakimg2{background-image: url(../../assets/images/home/homepage-img-安全监管系统.png)}
	.xst-product-bakimg3{background-image: url(../../assets/images/home/homepage-img-激光雷达系统.png)}
	.xst-product-bakimg4{background-image: url(../../assets/images/home/homepage-img-视频监控系统.png)}
	.xst-product-bakimg5{background-image: url(../../assets/images/home/homepage-img-NBIOT系统.png)}
	.xst-product-content-top{font-size: 40px;color: #fff;line-height: 90px;letter-spacing: 2px;font-family: MicrosoftYaHeiSemibold;}
	.xst-product-content-main {font-size: 24px;font-family: MicrosoftYaHei;color: #FFFFFF;line-height: 32px;letter-spacing: 1px;width: 80%; text-align: center;}
	// 产品介绍结束---------------------------------
	// 项目案例 start-----------------------------
	.xst-project{margin-top: 0px;}
	.xst-project-content{width: 100%; position: relative; margin-top: 0px;}
	.xst-project-back{height: 322px; width: 198px; background-color: #122A88; position: absolute; z-index: 0;}
	.xst-project-list{ width: 1130px; z-index: 2;display: flex;align-items: center;}
	.xst-project-item{
		    position: relative;
		height: 268px; width: 268px;border-radius: 2px;margin-top: 24px;
		.handleDialog{
			position: absolute;z-index: 5x;background: rgba(0, 0, 0, 0.6);height: 228px;width: 268px;
			.content{
				height: 180px;width: 100%;padding: 30px 20px 10px 20px;color: white; font-family: MicrosoftYaHei;
				.content-title{width: 100%; -webkit-line-clamp: 2; font-size: 20px; line-height: 28px;}
				.content-sub-title{ -webkit-line-clamp: 3; font-size: 14px; line-height: 20px; margin-top: 16px;}
			}
			.content-button{ cursor: pointer; font-size: 12px; width: 80px; height: 24px; border-radius: 1px; border: 1px solid #FFFFFF; color: #FFF; margin-left: 20px;display: flex;justify-content: center;align-items: center;}
		}
	}
	
	.xst-left-margin{margin-left: 14px;}
	.xst-right-margin{margin-right: 15px;}
	.xst-project-img{height: 268px;width: 268px;border-radius: 2px;}
	.xst-project-title-back{ position: absolute; top: 220px; height: 48px; background-color: #000; opacity: 0.5; width: 268px; color: white; 
			z-index: 4; display: flex;align-items: center;padding-left: 14px;border-radius: 0 0 2px 2px;}
	.xst-project-title{padding: 0 15px 0 15px;position: absolute; bottom: 15px; color: white;z-index: 56; width: 250px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;}
	
	.project {
		// height: 925px;
		display: flex;justify-content: center;
		// 案例悬浮
		.hover {display: unset;}
	}
.selectCalz{
	margin-left: 20px;
	width: 560px;
	height:60px;
	background: #FFFFFF;
	
	
	
}
.dateCalz{
	width: 560px;
	height: 40px;
	background: #FFFFFF;
	border-radius: 2px;
	border: 1px solid #DDDDDD;
}

.project{
	display: flex;
    justify-content: center;
	margin-bottom: 30px;

	.project-list{
		display: flex;
		padding: 60px 30px 0 30px;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	.project-list-item{
		width: 270px;
		height: 400px;
		display: block;
	}
	.case_title{
		height: 110px;
		background-color: white;
		border: 0.1px solid gray;
		display: flex;
		justify-content: start;
		align-items: center;
		padding: 0 20px 0 20px;

		img{
			width: 40px; 
			height: 70px;
		}
	}

	.handleDialog {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		background: rgba(0, 0, 0, 0.6);
		width: 100%;
		height: 100%;
		border-radius: 2px;
	}

	// 案例悬浮
	.hover {
		display: unset;
	}

}
</style>
